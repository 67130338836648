<template>
    <a-banner v-if="!isKamManagedUser">
        <!-- for reference only <span
            v-if="
                isBetween(['2024-07-03 11:00'], ['2024-07-07 11:00']) &&
                !isNewSimplerTrafficBuyer
            "
        >
            Discover Our New Traffic Playbook For 2024
            <a href="https://letsgolook.at/TrafficPlaybook2024" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP HERE
            </a>
        </span> -->

        <div v-if="isAsigoBuyerOnly" class="mb-4">
            We are excited to announce that Asigo has been upgraded to DropServe
            with new sales material, new software and new strategies to help you
            maximize your sales and profits.
            <br />
            Review our new training to discover the new platform and take part
            in our latest challenge and to grow your hands-off agency.
            <br />
            Join the sessions
            <a href="/training/dropserve/index" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                here
            </a>
        </div>

        <div v-if="isDropServeBuyer" class="my-4">
            Welcome to the DropServe, your DropService whitelabel platform where
            we will guide you on starting and scaling your own DropService
            business. Start here:
            <a href="https://www.dropserve.ai/thankyou" target="_blank">
                https://www.dropserve.ai/thankyou
            </a>
        </div>

        <div v-if="isReseller && !isDropServeBuyer" class="my-4">
            Your Amp Sales Funnel has been updated to a simpler, more refined
            version that will help you to improve conversions.
            <br />
            To set this up and start moving leads forward,
            <a
                href="https://docs.google.com/document/d/1wslOSG9LE4mG3TfSx0TLGokuJmsnZhY0m5heSFBhHYQ/edit"
                target="_blank"
            >
                <v-icon color="primary" x-small>arrow-right</v-icon>
                please follow the steps listed here
            </a>
        </div>

        <!-- Banners For All Users -->
        <div
            v-if="
                (isDropServeBuyer || isAsigoBuyer) &&
                (isBetween(['2024-12-13 11:00'], ['2024-12-16 11:00']) ||
                    isBetween(['2024-12-24 11:00'], ['2025-01-02 11:00']))
            "
            class="my-4"
        >
            Do You Have Your First Client And Are Motivated To Scale Beyond 6
            And 7 Figures In 2025? We May Be Able To Help You (For 2 Months For
            FREE)
            <a href="http://letsgolook.at/experiment" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Watch This Short Video For Details
            </a>
        </div>

        <div
            v-if="isBetween(['2024-12-16 11:00'], ['2024-12-22 11:00'])"
            class="my-4"
        >
            [2024 CLOSE OUT] Save x$1,000’s On Our Most Powerful Distribution
            And Get A FREE New “AI Answers” Search Traffic Training!
            <a href="http://letsgolook.at/2024CloseOut" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                RSVP For The Free Traffic Training Here
            </a>
        </div>
        <div
            v-if="isBetween(['2024-12-22 11:00'], ['2024-12-24 11:00'])"
            class="my-4"
        >
            [LAST CHANCE!] Single Credit Option For Premium Big 6 Distribution
            Now Available - Test Drive Our Most Powerful Distribution For A
            Smaller Investment! ::CLOSING SOON::
            <a href="https://letsgolook.at/Big6InsiderSingles" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Click Here To Get Your Big 6 Savings!
            </a>
        </div>

        <div
            v-if="isBetween(['2025-01-02 11:00'], ['2025-01-05 11:00'])"
            class="my-4"
        >
            Unleash Your 7-8 Figure Potential @ AmpiFire Unleashed 2025 +
            AmpiFire Subscribers Get A $190 Discount!
            <a href="http://letsgolook.at/unleashed" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Get Your Tickets Here
            </a>
        </div>

        <div
            v-if="isBetween(['2025-01-05 11:00'], ['2025-01-06 12:00'])"
            class="my-4"
        >
            [LAST CHANCE] We Go Live Monday @ 11am + Claim Your $190 Discount
            for AmpiFire Unleashed 2025!
            <a href="http://letsgolook.at/unleashed" target="_blank">
                <v-icon color="primary" x-small>arrow-right</v-icon>
                Get Your Tickets For
                <span class="text-decoration-line-through">$197</span>
                $7 Here
            </a>
        </div>

        <div v-if="showPressCableUserWelcomeMessage" class="my-4">
            You have PressCable credits remaining. Upgrade to Amp Credits
            <router-link to="/credits/convert">
                Here
                <v-icon color="primary" x-small>arrow-right</v-icon>
            </router-link>
        </div>
    </a-banner>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters, mapState } from 'vuex';

import ABanner from '@/components/ABanner';

@Component({
    components: {
        ABanner
    },
    computed: {
        ...mapGetters('user', ['isReseller']),
        ...mapState('user', [
            'isLociCycleBuyer',
            'isAsigoBuyer',
            'isDropServeBuyer',
            'isBoringMethodBuyer',
            'isNewSimplerTrafficBuyer',
            'isPressCableUser',
            'hasPressCableCredits',
            'isKamManagedUser'
        ])
    }
})
export default class WelcomeBanner extends Vue {
    isReseller!: boolean;
    isLociCycleBuyer!: boolean;
    isAsigoBuyer!: boolean;
    isDropServeBuyer!: boolean;
    isBoringMethodBuyer!: boolean;
    isNewSimplerTrafficBuyer!: boolean;
    isPressCableUser!: boolean;
    hasPressCableCredits!: boolean;
    isKamManagedUser!: boolean;

    get timestamp() {
        return Math.round(new Date().getTime() / 1000);
    }

    get showPressCableUserWelcomeMessage() {
        return this.isPressCableUser && this.hasPressCableCredits;
    }

    get isValidAsigoBuyer() {
        return this.isAsigoBuyer || this.isDropServeBuyer;
    }

    get isAsigoBuyerOnly() {
        return this.isAsigoBuyer && !this.isDropServeBuyer;
    }

    isBetween(
        [fromDate, fromZone = 'America/New_York']: string[],
        [toDate, toZone = 'America/New_York']: string[]
    ) {
        const now = this.$date();

        return (
            now.isAfter(this.$date(fromDate).tz(fromZone, true)) &&
            now.isBefore(this.$date(toDate).tz(toZone, true))
        );
    }
}
</script>
